import {
  Box,
  Button,
  makeStyles,
  useTheme,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setFlightSearchType } from '../../store/flights/searchAction'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', marginBottom: theme.spacing(10) },
  selected: {
    border: 'unset',
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: '#cdebeb',
    color: '#007e7e',
    '&:hover': {
      backgroundColor: '#cdebeb',
    },
    padding: '2px 18px',
  },
  unselected: {
    border: 'unset',
    textTransform: 'none',
    color: 'black',
    backgroundColor: 'white',
    fontWeight: theme.typography.fontWeightMedium,
    padding: '2px 18px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  container: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: '8px',
      paddingBottom: '8px',
      borderBottom: '1px solid #e6e6e6',
    },
  },
  leftButton: {
    borderRadius: '6px',
    border: '1px solid #e6e6e6',
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    }
  },
  middleButton: {
    borderRadius: '2px',
    border: '1px solid #e6e6e6',
    borderLeft: 'unset',
    borderRight: 'unset',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    }
  },
  rightButton: {
    borderRadius: '6px',
    border: '1px solid #e6e6e6',
    borderTopLeftRadius: '2px',
    borderBottomLeftRadius: '2px',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    }
  },
}))

function FlightTypeSwitcher() {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles(theme)
  const { searchType } = useSelector((state) => state.flights)
  const { t } = useTranslation()

  const handleClick = (type) => {
    if (type === searchType) return
    dispatch(setFlightSearchType(type))
  }

  return (
    <Box className={classes.container}>
      <Button
        className={classNames(
          classes.leftButton,
          searchType == 'oneWay' ? classes.selected : classes.unselected
        )}
        onClick={() => handleClick('oneWay')}
        disableRipple
        disableElevation
      >
        {t('flight oneway')}
      </Button>
      <Button
        className={classNames(
          classes.middleButton,
          searchType == 'roundTrip' ? classes.selected : classes.unselected
        )}
        onClick={() => handleClick('roundTrip')}
        disableRipple
        disableElevation
      >
        {t('flight with return')}
      </Button>
      <Button
        className={classNames(
          classes.rightButton,
          searchType == 'multiStop' ? classes.selected : classes.unselected
        )}
        onClick={() => handleClick('multiStop')}
        disableRipple
        disableElevation
      >
        {t('flight multistop')}
      </Button>
    </Box>
  )
}

export default FlightTypeSwitcher
