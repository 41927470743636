import React, { memo } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MobileSearchNav from '../../components/search/mobileSearchNav'
import UserSearch from '../../components/reusable/usersSearch'
import ReusableDatePicker from '../../components/reusable/datePicker/reusableDatePicker_new'
import AutoCompleteInput from '../../components/reusable/autoCompleteInput'
import AutoCompleteInputHotel from '../../components/reusable/autoCompleteInputHotel'
import { useTranslation } from 'react-i18next'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import { IconButton, useMediaQuery } from '@material-ui/core'
import { ReactComponent as DoubleArrow } from '../../assets/images/Arrows-WHT.svg'
import SvgIcon from '@material-ui/core/SvgIcon'
import SelectCounts from '../../components/reusable/selectCounts'
import CompaniesSearch from '../../components/reusable/companiesSearch'
import { useSelector } from 'react-redux'
import ReusableDatpicker from '../../components/reusable/datePicker/reusableDatePicker_new'
import { Lock } from '@material-ui/icons'

const isApp = window.location.hostname === 'app.citycity.se'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: isApp ? theme.spacing(5) : theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },

  searchBtn: {
    fontWeight: 600,
    letterSpacing: 1.1,
    fontSize: '16px',
    borderRadius: theme.spacing(4),
    padding: theme.spacing(2),
  },
  box: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.spacing(0.5),
  },
  fromInputBox: {
    padding: theme.spacing(1),
    // border: `1px solid ${theme.palette.border}`,
    borderBottom: `1px dashed ${theme.palette.border}`,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
  },
  toInputBox: {
    padding: theme.spacing(1),
    width: '100%',
    // border: `1px solid ${theme.palette.border}`,
    borderTop: 0,
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(0, 0, 0.5, 0.5),
  },
  swapContainer: {
    position: 'absolute',
    right: 24,
    top: 42,
    background: theme.palette.common.white,
  },
  swapButton: {
    padding: 0,
    transform: 'rotate(90deg)',
  },

  errorBox: {
    border: `1px solid #e02020`,
    borderRadius: theme.spacing(0.5),
    width: '100%',
  },

  destinationBox: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.spacing(0.5),
    width: '100%',
  },

  hotelErrorBox: {
    border: `1px solid #e02020`,
    borderRadius: theme.spacing(0.5),
  },
  eventIcon: {
    color: theme.palette.iframeSecondary.main,
  },

  lockIcon: {
    height: '100%',
    marginTop: '-3px',
    color: theme.palette.primary.main,
  },
}))

const MainSearchMobile = ({
  /* */
  from,
  entityType = null,
  setFrom,
  to,
  setTo,
  setAgentCompany,
  /**/
  departure,
  setDeparture,
  arrival,
  setArrival,
  /* */
  travellers,
  setTravellers,
  switchDestinations,
  toggleTravelType,
  isOneWay,
  onSubmit,
  /* */
  getCities,
  optionLabel,
  array,
  renderOption,
  /**/
  HOTEL,
  setHotel,
  /**/
  setIsPopoverOpenFrom,
  isPopoverOpenFrom,
  setIsPopoverOpenTo,
  isPopoverOpenTo,
  setIsPopoverOpenTraveller,
  isPopoverOpenTraveller,
  counts,
  modifyCounts,
  /**/
  setTicketClass,
  ticketClass,
  ticketClasses,
  TRAIN,
  earliestDate,
  latestDate,
  isToLocked,
  usersDisabled,
  disableTripTypeSelection,
  disableReturn = false,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { user, authType, agentCompany } = useSelector((state) => state.auth)
  const { isIframe } = useSelector((state) => state.customization)
  return (
    <Paper className={classes.paper} elevation={0} paddingBottom={4}>
      {!disableTripTypeSelection && (
        <Box mb={0.5}>
          <MobileSearchNav />
        </Box>
      )}
      <Grid container spacing={1}>
        {!!user && authType === 'agent' && !disableTripTypeSelection && (
          <Grid item xs={12}>
            <Box
              paddingLeft={1}
              paddingTop={1}
              paddingBottom={1.75}
              className={classes.box}
            >
              <CompaniesSearch
                value={agentCompany}
                setValue={setAgentCompany}
                noLabel
              />
            </Box>
          </Grid>
        )}
        {(authType !== 'agent' || !!agentCompany?.uniqueId) && (
          <Grid item xs={12}>
            <Box
              paddingLeft={1}
              paddingTop={1}
              paddingBottom={1.75}
              className={
                !isPopoverOpenTraveller ? classes.box : classes.errorBox
              }
            >
              <UserSearch
                entityType={entityType}
                value={travellers}
                setValue={setTravellers}
                noLabel
                withUserModal={true}
                userShadow={false}
                setIsPopoverOpenTraveller={setIsPopoverOpenTraveller}
                isPopoverOpenTraveller={isPopoverOpenTraveller}
                setTicketClass={setTicketClass}
                ticketClass={ticketClass}
                ticketClasses={ticketClasses}
                disabled={usersDisabled}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12} container>
          {HOTEL ? (
            <Grid item xs={12}>
              <Box
                p={1}
                className={
                  !isPopoverOpenFrom ? classes.box : classes.hotelErrorBox
                }
              >
                <AutoCompleteInputHotel
                  value={from}
                  onChange={setHotel}
                  array={array}
                  fetch={getCities}
                  optionLabel={optionLabel}
                  destinationModal={true}
                  label={'Destination'}
                  icon
                  isPopoverOpenFrom={isPopoverOpenFrom}
                />
              </Box>
            </Grid>
          ) : (
            <Box
              className={
                !isPopoverOpenFrom && !isPopoverOpenTo
                  ? classes.destinationBox
                  : classes.errorBox
              }
            >
              <Grid item xs={12}>
                <Box className={classes.fromInputBox} position="relative">
                  <AutoCompleteInput
                    icon={
                      <RadioButtonUncheckedIcon
                        color="primary"
                        className={isIframe && classes.eventIcon}
                      />
                    }
                    renderOption={renderOption}
                    fetch={getCities}
                    value={from}
                    onChange={(_, value) => {
                      setFrom(value)
                      setIsPopoverOpenFrom(false)
                    }}
                    array={array}
                    optionLabel={optionLabel}
                    destinationModal={true}
                    label={t('search from')}
                    placeholder={t('search from')}
                    isPopoverOpenFrom={isPopoverOpenFrom}
                    isFrom={true}
                  />
                  <Box className={classes.swapContainer}>
                    {!isToLocked && (
                      <IconButton
                        onClick={switchDestinations}
                        className={classes.swapButton}
                      >
                        <SwapHorizIcon
                          color="primary"
                          className={isIframe && classes.eventIcon}
                        />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.toInputBox}>
                  <AutoCompleteInput
                    icon={
                      <LocationOnIcon
                        color="primary"
                        className={isIframe && classes.eventIcon}
                      />
                    }
                    renderOption={renderOption}
                    fetch={getCities}
                    value={to}
                    onChange={(_, value) => {
                      setTo(value)
                      setIsPopoverOpenTo(false)
                    }}
                    array={array}
                    optionLabel={optionLabel}
                    destinationModal={true}
                    label={t('search to')}
                    placeholder={t('search to')}
                    isPopoverOpenTo={isPopoverOpenTo}
                    isTo={true}
                  />
                </Box>
              </Grid>
            </Box>
          )}
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={disableReturn ? 12 : 6}>
            <Box
              paddingTop={1}
              paddingBottom="9px"
              paddingX={1}
              className={classes.box}
            >
              <ReusableDatePicker
                disableLabel
                value={departure}
                onChange={setDeparture}
                from={departure}
                to={arrival}
                isOutgoing
                back
                minDate={earliestDate}
                maxDate={latestDate}
              />
            </Box>
          </Grid>
          {TRAIN && (
            <Grid item xs={6}>
              <Box
                paddingTop={1}
                paddingBottom="9px"
                paddingX={1}
                className={classes.box}
              >
                <ReusableDatpicker
                  disableLabel
                  value={departure}
                  onChange={setDeparture}
                  placeHolder={t('search time')}
                  title={t('search time')}
                  from={departure}
                  to={arrival}
                  isOutgoing
                  back
                  HOTEL={false}
                  timeOnly
                  minDate={earliestDate}
                  maxDate={latestDate}
                />
              </Box>
            </Grid>
          )}
          {!disableReturn && (
            <Grid item xs={6}>
              <Box
                paddingTop={1}
                paddingBottom="9px"
                paddingX={1}
                className={classes.box}
              >
                <ReusableDatePicker
                  disableLabel
                  value={arrival}
                  onChange={setArrival}
                  toggleTravelType={toggleTravelType}
                  isOneWay={isOneWay}
                  HOTEL={HOTEL}
                  back={false}
                  from={departure}
                  to={arrival}
                  minDate={earliestDate || departure}
                  maxDate={latestDate}
                />
              </Box>
            </Grid>
          )}
          {TRAIN && (
            <Grid item xs={6}>
              <Box
                paddingTop={1}
                paddingBottom="9px"
                paddingX={1}
                className={classes.box}
              >
                <ReusableDatpicker
                  disableLabel
                  value={arrival}
                  onChange={setArrival}
                  placeHolder={t('search time')}
                  title={t('search time')}
                  from={departure}
                  to={arrival}
                  isOutgoing
                  back
                  HOTEL={false}
                  timeOnly
                  minDate={earliestDate}
                  maxDate={latestDate}
                />
              </Box>
            </Grid>
          )}
        </Grid>
        {HOTEL ? (
          <Grid item xs={12}>
            <Box pl={1} pr={1} className={classes.box}>
              <SelectCounts
                isHotel={true}
                counts={counts}
                modifyCounts={modifyCounts}
                mobileSearch
              />
            </Box>
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12}>
          <Box mt={HOTEL ? 0 : '11px'} mb={isMobile ? 0 : 7}>
            <Button
              disableElevation
              onClick={onSubmit}
              variant="contained"
              color="primary"
              fullWidth
              endIcon={
                <SvgIcon style={{ fontSize: '18px' }}>
                  <DoubleArrow />
                </SvgIcon>
              }
              className={classes.searchBtn}
            >
              {t('search go')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default memo(MainSearchMobile)
