import mapValues from 'lodash/mapValues'
import COMPANIES from '../../constants/flightCompanies'
import CONSTANTS from './constants'
import {
  RESET_SEARCH_FILTERS,
  SET_COMPANIES_FILTERS,
  SET_DURATION_FILTERS,
  SET_STOP_FILTERS,
  SET_TIME_FILTERS,
} from '../search/constants'

export const initialTrip = {
  date: undefined,
  time: '99:99',
}

export const initialTime = {
  from: '00:00',
  to: '24:00',
}

export const initialFilters = {
  shouldReset: false, // should filters reset before new search was done
  rebooked: false,
  refunded: false,
  stops: {
    0: true,
    1: true,
    2: true,
  },
  travelTypes: {
    air: true,
    bus: true,
    train: true,
  },
  companies: mapValues(COMPANIES, () => true),
  time: {
    trip: { ...initialTime },
    returnTrip: { ...initialTime },
  },
  travelTime: {
    trip: 100000000,
    returnTrip: 100000000,
  },
  maxTravelTimeTrip: 1440,
  maxTravelTimeReturnTrip: 1440,
  sortBy: 'standardPrice',
  sortDirection: 'asc',
  stopsTrip: {},
  stopsReturnTrip: {},
  companiesShow: {},
}

const initialState = {
  // Outgoing
  searchOutgoing: [],
  outgoingSearchToken: null,
  searchError: null,
  //
  stopsReturnTrip: null,

  stoptsTrip: null,

  // Passengers
  passengers: [],

  // Margins
  margins: [],

  //agency
  flightAgencyFilters: null,

  //Returning
  searchReturning: [],
  isLoading: false,
  outgoing: null,
  returning: null,
  totalPrice: 0,
  filters: initialFilters,
  //
  airports: [],

  // Multi
  searchMulti: [],
  selectedMulti: {},
  searchType: 'roundTrip',
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CONSTANTS.SET_FLIGHT_SEARCH_TYPE:
      return {
        ...state,
        searchType: payload,
      }
    case CONSTANTS.FETCH_OUTGOING_SEARCH:
      return {
        ...state,
        searchOutgoing: payload.data,
        outgoingSearchToken: payload.searchToken || null,
      }
    case CONSTANTS.FETCH_RETURNING_SEARCH:
      return {
        ...state,
        searchReturning: payload.data,
      }

    case CONSTANTS.FETCH_MULTI_SEARCH:
      return {
        ...state,
        searchMulti: payload.data,
        // outgoingSearchToken: payload.searchToken || null,
      }

    case CONSTANTS.SET_FLIGHT_MULTI: {
      if (!payload) {
        return {
          ...state,
          selectedMulti: {},
        }
      }
      const { flight, tripNumber } = payload
      let selected = { ...state.selectedMulti }

      if (flight) {
        selected[tripNumber] = flight
      } else {
        delete selected[tripNumber]
      }
      return {
        ...state,
        selectedMulti: selected,
      }
    }

    //
    case CONSTANTS.SET_FLIGHT_OUTGOING:
      return {
        ...state,
        outgoing: payload,
      }
    //
    case CONSTANTS.SET_FLIGHT_RETURNING:
      return {
        ...state,
        returning: payload,
      }
    case CONSTANTS.SET_FLIGHT_MARGINS:
      return {
        ...state,
        margins: payload,
      }
    case CONSTANTS.SET_FLIGHT_AGENCY_FILTERS:
      return {
        ...state,
        flightAgencyFilters: payload || null,
      }
    case CONSTANTS.SET_FLIGHT_AIRPORTS:
      return {
        ...state,
        airports: payload,
      }
    case CONSTANTS.SET_FLIGHT_PASSENGERS:
      return {
        ...state,
        passengers: payload,
      }
    case CONSTANTS.IS_SEARCH_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case CONSTANTS.CLEAR_FLIGHT_SEARCH:
      return {
        ...state,
        searchReturning: [],
        searchOutgoing: [],
        searchMulti: [],
        // Do not clear this token, needed for return search.
        // outgoingSearchToken: null,
        filters: initialFilters,
      }
    case CONSTANTS.SET_SEARCH_ERROR:
      return {
        ...state,
        searchError: payload,
      }
    case CONSTANTS.SET_CURRENT_FLIGHT_PRICE:
      return {
        ...state,
        totalPrice: payload,
      }

    case SET_STOP_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          stops: {
            ...state.filters.stops,
            ...payload,
          },
        },
      }
    case SET_DURATION_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          travelTime: {
            ...state.filters.travelTime,
            ...payload,
          },
        },
      }
    case SET_COMPANIES_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          companies: payload,
        },
      }
    case SET_TIME_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          time: {
            ...state.filters.time,
            ...payload,
          },
        },
      }
    case RESET_SEARCH_FILTERS:
      return {
        ...state,
        filters: initialFilters,
      }
    case CONSTANTS.RESET_STATE:
      return initialState
    default:
      return state
  }
}
