import React, { useEffect, memo, useState, Fragment } from 'react'
import { Box, Container, Grid, Paper, Button, Hidden } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useHistory } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { makeStyles } from '@material-ui/core/styles'
import ChoosenJourney from '../../containers/flights/details/choosenJourney'
import { useDispatch, useSelector } from 'react-redux'
import { closeAllModals } from '../../store/modals/modalAction'

import moment from 'moment'
import {
  getFlightSeatsAction,
  getFlightStandaloneCatalogue,
  getFlightFamilyPricing,
  getFlightMiddleStepSettingsAction,
  clearAllFlightDetailsAction,
} from '../../store/flightDetails/flightDetailsActions'
import SelectTicket from '../../containers/flights/details/selectTicket'
import TotalPriceDetails from '../../containers/flights/details/totalPriceDetails'
import { addToCheckoutAction } from '../../store/checkout/checkoutActions'
import {
  fetchHotelRatingsAction,
  fetchHotelSearchAction,
  resetHotelFilters,
  setHotelGuestsAction,
} from '../../store/hotels/hotelsAction'
import { fetchCityByIata } from '../../repositories/flights'

import MobileHeader from '../../containers/header/mobileHeader/mobileHeader'
import DesktopDetailsHeader from '../../containers/header/desktopDetailsHeader/desktopDetailsHeader'
import { setBreadcrumbType } from '../../store/breadcrumbs/breadcrumbsAction'
import {
  calculatePrice,
  getFFBags,
  hotelCoords,
} from '../../utils/flightGeneralFunctions'
import isSameDay from '../../utils/isSameDay'
import { useTranslation } from 'react-i18next'
import {
  setFlightSearchParameters,
  setHotelSearchParameters,
} from '../../store/search/searchAction'
import isIframed from '../../utils/isIframed'
import useEnabledBookingTypesHook from '../../hooks/useEnabledBookingTypesHook'
import { is } from 'date-fns/locale'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
  },
  borderRadius: {
    borderRadius: '12px',
  },
  spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: '0 10px',
      marginLeft: 0,
      fontWeight: 500,
    },
  },
  backButton: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(2),
    textTransform: 'none',
    left: 0,
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    padding: '16px 20px',
    borderRadius: theme.spacing(10),
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  arrowIcons: {
    color: theme.palette.action.disabled,
    fontWeight: 600,
  },
  continueBtn: {
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 'normal',
    letterSpacing: '1.1px',
    padding: '23px 60px',
  },
  btnContainer: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  fixedDiv: {
    position: 'sticky',
    top: !isIframed && 100,
  },
}))

const FlightDetails = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector((state) => state.auth)
  const flightsState = useSelector((state) => state.flights)
  const { outgoing, returning, selectedMulti } = flightsState
  const searchParams = useSelector((state) => state.search.flights)
  const { travellers: passengers, ticketClass } = searchParams
  const flightDetailsState = useSelector((state) => state.flightDetails)
  const prices = calculatePrice(flightDetailsState, flightsState)
  const { isHotelsEnabled } = useEnabledBookingTypesHook()

  const { hotels: hotelsSearchData } = useSelector((state) => state.search)

  const {
    currentFare,
    returnFare,
    passengerSeats,
    selectedBaggage,
    selectedMultistopBaggage,
    multistopFares,
  } = flightDetailsState
  const [aita, setAita] = useState(null)
  const [skipDataRequests, setSkipDataRequests] = useState(false)

  const isDataLoaded = !flightDetailsState.fareLoading

  const formatdate = (date) => moment(date).format('YYYY-MM-DD')
  const forwardDate = (date) => moment(date).add(1, 'days').format('YYYY-MM-DD')

  const isMultiStop = Object.values(selectedMulti).length > 0

  const useMiddleStep =
    flightDetailsState.middleStepSettings?.settings?.useMiddlestep &&
    isHotelsEnabled &&
    !isMultiStop

  const multiItems = Object.values(selectedMulti)
  const firstMultiItem = multiItems[0]
  const checkOutDate = isMultiStop
    ? formatdate(multiItems[multiItems.length - 1].date)
    : returning
      ? formatdate(returning.date)
      : forwardDate(outgoing.date)

  useEffect(() => {
    dispatch(setBreadcrumbType('flights'))
    dispatch(closeAllModals())
  }, [dispatch])

  // Flag multistop combination
  const isCombination =
    isMultiStop && multiItems[multiItems.length - 1].isCombination

  useEffect(() => {
    const fetchAwaita = async () => {
      let item = firstMultiItem || outgoing
      const result = await fetchCityByIata(item.to.shortName)
      setAita(result)
    }
    fetchAwaita()
  }, [outgoing, firstMultiItem])

  useEffect(() => {
    const tClass = ticketClass || 'economy'
    if (!skipDataRequests) {
      dispatch(
        getFlightFamilyPricing({
          outgoing,
          returning,
          selectedMulti: isMultiStop && selectedMulti,
          passengers,
          ticketClass: tClass,
        })
      )
    }
    dispatch(getFlightMiddleStepSettingsAction())

    return () => {
      dispatch(clearAllFlightDetailsAction())
    }
  }, [
    dispatch,
    outgoing,
    returning,
    selectedMulti,
    passengers,
    isAuthenticated,
    ticketClass,
    skipDataRequests,
  ])

  useEffect(() => {
    if (!skipDataRequests) {
      dispatch(
        getFlightStandaloneCatalogue({
          outgoing,
          returning,
          currentFare,
          returnFare,
          multiItems: isMultiStop && Object.values(selectedMulti),
        })
      )
      dispatch(
        getFlightSeatsAction({
          outgoing,
          returning,
          multiItems: isMultiStop && Object.values(selectedMulti),
        })
      )
    }
  }, [
    dispatch,
    currentFare,
    returnFare,
    outgoing,
    returning,
    skipDataRequests,
    multistopFares,
  ])

  useEffect(() => {
    const fetchHotel = () => {
      const { lat, lng } = hotelCoords(aita)
      const item = firstMultiItem || outgoing
      if (item) {
        const data = {
          countryCode: aita['Country Code'],
          lat: lat.toFixed(7),
          lng: lng.toFixed(7),
          checkIn: item.date,
          checkOut: checkOutDate,
          guests: passengers.length <= 3 ? String(passengers.length) : '3',
          roomCount: '1',
        }

        dispatch(fetchHotelSearchAction(data))
        dispatch(
          fetchHotelRatingsAction({
            lat: lat,
            lng: lng,
            countryCode: aita['Country Code'],
          })
        )
      }
    }

    if (aita?.lat && aita?.lng) {
      fetchHotel()
    }
  }, [aita, dispatch, outgoing, passengers, checkOutDate])

  const goBack = () => history.goBack()

  const goForwardAndSetCheckout = async () => {
    // fare family bags
    const isMultiTicket = returning?.original?.isMultiTicket
    let resBags = {}
    let outboundBags = getFFBags(
      currentFare,
      outgoing,
      !isMultiTicket ? returning : null
    )

    if (isMultiTicket) {
      let returnBags = getFFBags(returnFare, returning, null)
      if (outboundBags?.general.length > 0)
        resBags.outbound = [...outboundBags.general]
      if (returnBags?.general.length > 0)
        resBags.return = [...returnBags.general]
    } else if (
      outboundBags?.outbound?.length > 0 ||
      outboundBags?.return?.length > 0
    ) {
      if (outboundBags?.outbound.length > 0)
        resBags.outbound = [...outboundBags.outbound]
      if (outboundBags?.return.length > 0)
        resBags.return = [...outboundBags.return]
    } else if (outboundBags?.general.length > 0) {
      resBags.general = [...outboundBags.general]
    }

    if (!Object.keys(resBags)?.length) resBags = null

    if (isMultiStop) {
      const incity = await fetchCityByIata(firstMultiItem.to.shortName)
      setSkipDataRequests(true)

      const payload = {
        type: 'Flight',
        city: incity ? [incity['City ENG'], incity['City SWE']] : null, // Workaround for missing flight destination
        totalPrice: prices.total,
        co2: Object.values(selectedMulti).reduce(
          (prev, curr) => curr.co2 + prev,
          0
        ),
        isMultiStop: true,
        isCombination: isCombination,
        seats: passengerSeats,
        ffBags: resBags,
        trips: [
          ...Object.entries(selectedMulti).map((multi) => {
            let [stopId, item] = multi
            let fare = multistopFares[stopId]
            let baggage = selectedMultistopBaggage[stopId]

            return {
              searchCodes: {
                from: item.from.shortName,
                to: item.to.shortName,
              },
              outgoing: item,
              totalOutboundPrice: prices.total,
              currentFare: fare,
              co2: item.co2,
              totalPrice: prices.total,
              farePrice: prices.farePerUser,
              baggage: baggage,
            }
          }),
        ],
        passengers: [...passengers],
      }
      dispatch(addToCheckoutAction(payload))

      return history.push('/checkout')
    } else {
      setSkipDataRequests(true)

      const payload = {
        type: 'Flight',
        searchCodes: {
          from: outgoing.from.shortName,
          to: outgoing.to.shortName,
        },
        city: aita ? [aita['City ENG'], aita['City SWE']] : null, // Workaround for missing flight destination
        seats: passengerSeats,
        ffBags: resBags,
        totalPrice: prices.total,
        farePrice: prices.farePerUser,
        totalOutboundPrice: prices.outboundTotal,
        totalReturnPrice: prices.returnTotal,
        outgoing: outgoing,
        returning: returning,
        baggage: selectedBaggage,
        currentFare: currentFare,
        returnFare: returnFare,
        passengers: [...passengers],
        co2: outgoing.co2 + (returning?.co2 || 0),
      }
      dispatch(addToCheckoutAction(payload))
    }

    const checkin = moment(outgoing.arrival).format('YYYY-MM-DD')

    const sameDay = isSameDay(checkin, checkOutDate)
    if (sameDay) {
      return history.push('/checkout')
    }

    // set hotel search data
    const { cityValue, city } = hotelsSearchData

    dispatch(
      setHotelSearchParameters(
        cityValue,
        city,
        checkin,
        checkOutDate,
        [...passengers],
        passengers.length,
        { beds: { count: 1 } }
      )
    )
    dispatch(resetHotelFilters())
    dispatch(setHotelGuestsAction([...passengers]))

    if (useMiddleStep && aita && !searchParams?.extendCheckout) {
      const { lat, lng } = hotelCoords(aita)

      const url = `/middlestep/flights/${aita['Country Code']}/${lat}/${lng}/${checkin}/${checkOutDate}/${passengers.length}/${passengers.length}/`
      history.push(url)
    } else {
      if (searchParams?.extendCheckout) {
        dispatch(
          setFlightSearchParameters(
            searchParams?.from || null,
            searchParams?.to || null,
            searchParams?.departure || null,
            searchParams?.arrival || null,
            searchParams?.travellers || [],
            searchParams?.travelerCounts || null,
            searchParams?.ticketClass || 'economy',
            false
          )
        )
      }
      const url = `/checkout`
      history.push(url)
    }
  }

  // select content (general or adding user information)
  const getContent = () => {
    return (
      <Fragment>
        {isMobile ? (
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.borderRadius}>
              <TotalPriceDetails loading={!isDataLoaded} />
            </Paper>
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12} md={7} sm={12}>
          <SelectTicket />
        </Grid>
        <Grid item xs={12} md={5} sm={12}>
          <Box className={classes.fixedDiv}>
            <Paper elevation={0} className={classes.borderRadius}>
              <ChoosenJourney />
            </Paper>
            {isMobile ? (
              ''
            ) : (
              <Paper elevation={0} className={classes.borderRadius}>
                <Box mt={isMobile ? 0 : 3} height="100%">
                  <TotalPriceDetails loading={!isDataLoaded} />
                </Box>
              </Paper>
            )}
          </Box>
        </Grid>
      </Fragment>
    )
  }

  return (
    <Box>
      <Hidden smUp>
        <MobileHeader tripPrice={prices.total} />
      </Hidden>
      <Hidden smDown>
        <DesktopDetailsHeader tripPrice={prices.total} />
      </Hidden>

      {/* *** CONTENT  *** */}
      <Box p={1} pb={29}>
        <Container maxWidth="lg" disableGutters>
          <Box mt={isMobile ? 1 : 3} mb={0}>
            <Grid
              container
              direction={isMobile ? 'column-reverse' : 'row'}
              spacing={isMobile ? 2 : 6}
            >
              {getContent()}
            </Grid>
          </Box>
          <Grid container spacing={isMobile ? 2 : 4}>
            <Grid item md={7} sm={12} xs={12}>
              <Box
                className={classes.btnContainer}
                mb={7}
                mt={isMobile ? 3 : 0}
              >
                <Hidden smDown>
                  <Button
                    disableElevation
                    disableRipple
                    onClick={goBack}
                    className={classes.backButton}
                    startIcon={<ArrowBackIcon color="primary" />}
                    variant="contained"
                  >
                    {t('back')}
                  </Button>
                </Hidden>
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  style={{ borderRadius: 50 }}
                  onClick={goForwardAndSetCheckout}
                  className={classes.continueBtn}
                >
                  {t('detail btn')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default memo(FlightDetails)
