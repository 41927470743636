import React, { memo, Fragment, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Box, useTheme, useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import CenterSearchbar from '../../hooks/centerSearchbar'

import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

import useFlightsHook from '../../hooks/useFlightsHook'
import useTravellers from '../../hooks/useTravellersHook'
import BannerRow from '../../containers/header/bannerRow'

import MultiCitySearch from '../../containers/search/multiCitySearch'
import {
  clearSelectedMulti,
  resetState,
  setFlightsPassengers,
} from '../../store/flights/searchAction'
import { setMapCenter } from '../../store/hotels/hotelsAction'
import { clearSelectedBaggage } from '../../store/flightDetails/flightDetailsActions'
import { removeAllCheckoutAction } from '../../store/checkout/checkoutActions'
import { buildUsersByCount } from '../../utils/general'
import moment from 'moment'
import FlightTypeSwitcher from '../../components/flights/flightTypeSwitcher'
import { setFlightMultiItems } from '../../store/search/searchAction'

const FlightsMultiCity = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { user, isAuthenticated } = useSelector((state) => state.auth)

  const { earliestDate, latestDate, allowedFlightDestination } =
    user?.bookingLink || {}
  const isToLocked = allowedFlightDestination?.length > 0

  const { travellers: initialTravellers } = useSelector(
    (state) => state.search.flights
  )

  const { airports, searchType } = useSelector((state) => state.flights)

  const { travellers, setTravellers, setAgentCompany } =
    useTravellers(initialTravellers)

  const { getCities, ticketClass, setTicketClass, ticketClasses } =
    useFlightsHook({
      history,
      i18n,
      dispatch,
      travellers,
    })

  useEffect(() => {
    if (searchType !== 'multiStop') {
      history.push('/flights')
    }
  }, [searchType])

  const onSubmit = (multistop) => {
    // console.log(JSON.stringify(multistop, null, 3))

    const tmpTravelers = isAuthenticated
      ? travellers
      : buildUsersByCount(counts)

    if (multistop?.length < 1 || tmpTravelers.length === 0) return

    dispatch(removeAllCheckoutAction())
    dispatch(clearSelectedBaggage())
    dispatch(setMapCenter(null))

    dispatch(clearSelectedMulti())
    dispatch(resetState())

    dispatch(setFlightsPassengers(tmpTravelers))

    dispatch(setFlightMultiItems(multistop))

    const from = multistop.map((stop) => stop.from.IATA).join('-')
    const to = multistop.map((stop) => stop.to.IATA).join('-')
    const departure = multistop
      .map((stop) => moment(stop.departure).format('YYYYMMDD'))
      .join('-')
    const newPath = `/flights/multi/${from}/${to}/${departure}/${tmpTravelers.length}/0/0/${ticketClass}/0`
    history.push(newPath)
  }

  const optionLabel = (options) => {
    return options['Airport']
      ? `${options['City']}, ${options['Country']} (${options.IATA})`
      : ''
  }

  const renderOption = (option, { inputValue }) => {
    const matches = match(
      `${option['City']}, ${option['Country']} (${option.IATA})`,
      inputValue
    )
    const parts = parse(
      `${option['City']}, ${option['Country']} (${option.IATA})`,
      matches
    )

    return (
      <Fragment>
        <Box mr={1}>
          <LocationOnIcon color="disabled" fontSize="large" />
        </Box>
        <Typography>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 700 : 400 }}
            >
              {part.text}
            </span>
          ))}
        </Typography>
      </Fragment>
    )
  }

  return (
    <>
      {!isMobile && <BannerRow bannerKey="flight"></BannerRow>}

      <Box style={{ display: 'block' }}>
        <FlightTypeSwitcher />
      </Box>

      <CenterSearchbar>
        <MultiCitySearch
          setAgentCompany={setAgentCompany}
          travellers={travellers}
          setTravellers={setTravellers}
          getCities={getCities}
          onSubmit={onSubmit}
          array={airports}
          optionLabel={optionLabel}
          renderOption={renderOption}
          defaultOptions={[]}
          setTicketClass={setTicketClass}
          ticketClass={ticketClass}
          ticketClasses={ticketClasses}
          earliestDate={earliestDate}
          latestDate={latestDate}
          isToLocked={isToLocked}
          usersDisabled={false}
        />
      </CenterSearchbar>
    </>
  )
}

export default memo(FlightsMultiCity)
