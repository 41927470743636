import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { useHistory } from 'react-router'
import { Hidden, useMediaQuery, useTheme } from '@material-ui/core'
import ExpandedSearchBar from '../../containers/search/expandedSearch'
import MobileBottomNavigation from '../../containers/footer/mobileBottomNavigation'
import MobileSearch from '../../containers/search/mobileSearch'
import CenterSearchbar from '../../hooks/centerSearchbar'
import useTravellers from '../../hooks/useTravellersHook'
import useBookingDates from '../../hooks/useBookingDatesHook'
import useCityChoose from '../../hooks/useCityChooseHook'
import useRoomCount from '../../hooks/useRoomCountHook'
import useHotels from '../../hooks/useHotelsHook'
import usePopover from '../../hooks/usePopoverHook'
import GuestExpandedSearchBar from '../../containers/search/guest/guestExpandedSearch'
import GuestMobileSearch from '../../containers/search/guest/guestMobileSearch'
import BannerRow from '../../containers/header/bannerRow'

const Hotels = ({ showSearchModes, currentSearchMode, setSearchMode }) => {
  // Hooks
  const dispatch = useDispatch()
  const { url } = useRouteMatch()
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { isAuthenticated, user } = useSelector((state) => state.auth)
  const MobSearchBar = isAuthenticated ? MobileSearch : GuestMobileSearch
  const SearchBar = isAuthenticated ? ExpandedSearchBar : GuestExpandedSearchBar

  const {
    earliestDate,
    latestDate,
    suggestedEarliestDate,
    suggestedLatestDate,
  } = user?.bookingLink || {}

  // Redux
  const { cities, city } = useSelector((state) => state.hotels)
  const {
    cityValue: initialCityValue,
    checkIn: initialCheckIn,
    checkOut: initialCheckOut,
    travellers: initialTravellers,
    roomCount: initialRoomCount,
  } = useSelector((state) => state.search.hotels)

  // useStates
  const { city: cityValue, setCityOnChange } = useCityChoose(
    initialCityValue,
    dispatch
  )

  const { checkIn, checkOut, depChange, arrOnChange } = useBookingDates(
    suggestedEarliestDate || initialCheckIn,
    suggestedLatestDate || initialCheckOut,
    earliestDate,
    latestDate
  )

  const { travellers, setTravellers, setAgentCompany } =
    useTravellers(initialTravellers)
  const { roomCount, incrementRoom, decrementRoom, setRoomCount } =
    useRoomCount(initialRoomCount)

  const {
    isPopoverOpenFrom,
    setIsPopoverOpenFrom,
    isPopoverOpenTraveller,
    setIsPopoverOpenTraveller,
    setIsPopoverOpenCheckIn,
    setIsPopoverOpenCheckOut,
  } = usePopover()

  const { onSubmit, getCities, optionLabel, counts, modifyCounts } = useHotels({
    cityValue,
    city,
    checkIn,
    checkOut,
    travellers,
    roomCount,
    url,
    dispatch,
    history,
    setIsPopoverOpenFrom,
    setIsPopoverOpenTraveller,
    setIsPopoverOpenCheckIn,
    setIsPopoverOpenCheckOut,
    incrementRoom,
    decrementRoom,
    setRoomCount,
  })

  return (
    <>
      <BannerRow bannerKey="hotel"></BannerRow>
      <CenterSearchbar>
        <Hidden mdUp>
          <MobSearchBar
            HOTEL
            /* */
            from={cityValue}
            setAgentCompany={setAgentCompany}
            setFrom={setCityOnChange}
            departure={checkIn}
            setDeparture={depChange}
            arrival={checkOut}
            setArrival={arrOnChange}
            /* */
            travellers={travellers}
            setTravellers={setTravellers}
            getCities={getCities}
            onSubmit={onSubmit}
            array={cities}
            optionLabel={optionLabel}
            setHotel={setCityOnChange}
            roomCount={roomCount}
            incrementRoom={incrementRoom}
            decrementRoom={decrementRoom}
            isPopoverOpenTraveller={isPopoverOpenTraveller}
            setIsPopoverOpenTraveller={setIsPopoverOpenTraveller}
            isPopoverOpenFrom={isPopoverOpenFrom}
            setIsPopoverOpenFrom={setIsPopoverOpenFrom}
            counts={counts}
            modifyCounts={modifyCounts}
            earliestDate={earliestDate}
            latestDate={latestDate}
          />
        </Hidden>

        <Hidden smDown>
          <SearchBar
            HOTEL
            /* */
            from={cityValue}
            setAgentCompany={setAgentCompany}
            setFrom={setCityOnChange}
            departure={checkIn}
            setDeparture={depChange}
            arrival={checkOut}
            setArrival={arrOnChange}
            /* */
            travellers={travellers}
            setTravellers={setTravellers}
            getCities={getCities}
            onSubmit={onSubmit}
            array={cities}
            optionLabel={optionLabel}
            setHotel={setCityOnChange}
            roomCount={roomCount}
            incrementRoom={incrementRoom}
            decrementRoom={decrementRoom}
            isPopoverOpenTraveller={isPopoverOpenTraveller}
            setIsPopoverOpenTraveller={setIsPopoverOpenTraveller}
            isPopoverOpenFrom={isPopoverOpenFrom}
            setIsPopoverOpenFrom={setIsPopoverOpenFrom}
            counts={counts}
            modifyCounts={modifyCounts}
            showSearchModes={showSearchModes}
            currentSearchMode={currentSearchMode}
            setSearchMode={setSearchMode}
            earliestDate={earliestDate}
            latestDate={latestDate}
          />
        </Hidden>
      </CenterSearchbar>
      <Hidden smUp>
        <MobileBottomNavigation />
      </Hidden>
    </>
  )
}

export default memo(Hotels)
