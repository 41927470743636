import CONSTANTS from './constants'
import {
  fetchPostGetSeats,
  fetchPostStandaloneCatalogue,
  fetchPostFamiliesPricing,
  fetchGetMiddleStepSettings,
} from '../../repositories/flights'

import {
  cleanFlightCataloguePayload,
  cleanFamiliesPricing,
} from './helpers/helpers'

import { parseSeatsRes } from './helpers/parseSeats'
import { calculateFlightPrice } from '../../utils/priceMargins'
import { flightClassGrades } from '../../utils/policy'
import { filterMargins } from '../flights/helpers/general'
import { cancelRyByKeyPart } from '../../repositories/agent'

const rqCTKType = '::flightDetails::'
export const getFlightSeatsAction =
  ({ outgoing, returning, multiItems }) =>
  async (dispatch, getState) => {
    // getoutgoing seats

    const outgoingTrip = outgoing?.original?.trip
    const returningTrip = returning ? returning.original.returnTrip : []
    const isMultiTicket = returning?.original?.isMultiTicket
    let mashTogether = null
    if (multiItems) {
      mashTogether = []
      for (const multiTrip of multiItems) {
        mashTogether = [...mashTogether, ...multiTrip.original.trip]
      }
    } else {
      mashTogether = [...outgoingTrip, ...returningTrip]
    }
    // console.log(mashTogether)

    // Mash Together the lists
    const { seats, returnFare, currentFare, multistopFares } =
      getState().flightDetails
    if (seats.length) {
      dispatch({ type: CONSTANTS.CLEAR_ALL_SEATS })
      dispatch(setPassengerSeatings([]))
    }
    let fareByFlights = currentFare?.byFlights || {}

    if (isMultiTicket && returnFare?.byFlights) {
      fareByFlights = { ...fareByFlights, ...returnFare.byFlights }
    }

    if (multiItems) {
      // console.log(multistopFares)
      Object.values(multistopFares).forEach((fare) => {
        // console.log(fare)
        if (fare.byFlights) {
          fareByFlights = { ...fareByFlights, ...fare.byFlights }
        }
      })
    }
    // console.log(fareByFlights)

    // Iterate them
    dispatch(isSeatsFetching(true))
    const fetchedSeats = []
    for (const flight of mashTogether) {
      try {
        const { uniqueInd: flightUId } = flight
        if (fareByFlights[flightUId]) {
          flight.fareBasis = fareByFlights[flightUId]?.ffFareBasis
          flight.bookingclass = fareByFlights[flightUId]?.bookingClass
          flight.cabinCode = fareByFlights[flightUId]?.cabin
        }
        const seats = await fetchPostGetSeats(
          { flight },
          `${flightUId}-fetchSeats${rqCTKType}`
        )

        const parseddata = parseSeatsRes(seats)

        // Special case for Emirates
        // Disable seating for Economy
        if (flight.airline === 'EK' && ['M'].includes(flight.cabinCode)) {
          // console.log('disable seats')
          parseddata.isFreeExists = false
        }

        const data = {
          ...parseddata,
          flightnumber: flight.flightnumber,
          flightKey: flight.uniqueInd,
          flight: flight,
        }

        fetchedSeats.push({ ...data })
      } catch (e) {
        console.log(e)
        fetchedSeats.push({
          flightnumber: flight.flightnumber,
          flightKey: flight.uniqueInd,
          flight: flight,
        })
      }
    }
    dispatch({ type: CONSTANTS.SET_FLIGHT_SEATS, payload: fetchedSeats })
    dispatch(isSeatsFetching(false))
  }

export const getFlightStandaloneCatalogue =
  ({ outgoing, returning, currentFare, returnFare, multiItems }) =>
  async (dispatch) => {
    try {
      // Fetches and sets outgoing
      dispatch(isCatalogueFetching(true))

      if (multiItems) {
        const merge = Object.values(multiItems)
          .map((item) => item.original.trip)
          .flat()
        const multiData = cleanFlightCataloguePayload(merge, currentFare)
        const fetchMulti = await fetchPostStandaloneCatalogue(
          multiData,
          `fetchMultiBags${rqCTKType}`
        )
        dispatch({
          type: CONSTANTS.SET_MULTISTOP_CATALOGUE,
          payload: fetchMulti,
        })
      } else {
        const { trip } = outgoing.original
        const returnTrip = returning ? returning.original.returnTrip : []
        const isMultiTicket = returning?.original?.isMultiTicket
        const merge =
          returning && !isMultiTicket ? [...trip, ...returnTrip] : trip

        const outgoingData = cleanFlightCataloguePayload(merge, currentFare)
        const fetchOutgoing = await fetchPostStandaloneCatalogue(
          outgoingData,
          `fetchOutboundBags${rqCTKType}`
        )
        dispatch({
          type: CONSTANTS.SET_OUTGOING_CATALOGUE,
          payload: fetchOutgoing,
        })

        // Fetches and sets Returning
        if (isMultiTicket) {
          const returningData = cleanFlightCataloguePayload(
            returnTrip,
            returnFare
          )
          const fetchReturning = await fetchPostStandaloneCatalogue(
            returningData,
            `fetchReturnBags${rqCTKType}`
          )
          dispatch({
            type: CONSTANTS.SET_RETURN_CATALOGUE,
            payload: fetchReturning,
          })
        }
      }

      dispatch(isCatalogueFetching(false))
    } catch (e) {
      dispatch(clearCatalogueData())
      console.log(e)
    } finally {
      dispatch(isCatalogueFetching(false))
    }
  }

export const getFlightFamilyPricing =
  ({ outgoing, returning, selectedMulti, passengers, ticketClass }) =>
  async (dispatch, getState) => {
    try {
      const { margins } = getState().flights
      const isTwoWay = !!(outgoing && returning)
      const cabinPolicyGrade =
        flightClassGrades?.[outgoing?.policyCabin || null] || null
      const currentCabinGrade = flightClassGrades?.[ticketClass || null] || null
      const possibleGrades = ['economy', 'economyPremium', 'business']

      const tmpOCabin = []
      if (!!ticketClass) tmpOCabin.push(ticketClass)
      if (!!currentCabinGrade) {
        possibleGrades.forEach((pg) => {
          const pgNum = flightClassGrades?.[pg || null] || null
          if (
            !pgNum ||
            pgNum <= currentCabinGrade ||
            (cabinPolicyGrade && pgNum > cabinPolicyGrade)
          )
            return false
          tmpOCabin.push(pg)
        })
      }

      const numPassengers = passengers?.length || 1

      // Multistop - handle as normal MTK trips
      if (selectedMulti) {
        dispatch(isFareFetching(true))

        const farePromises = Object.entries(selectedMulti).map(
          async ([stopId, item]) => {
            const { trip } = item.original
            const merge = trip

            const multiData = cleanFamiliesPricing({
              flights: merge,
              passengers,
            })

            const multiFetch = await fetchPostFamiliesPricing(
              multiData,
              `fetchMultistopFF${stopId}`
            )

            for (let ff of multiFetch) {
              const filteredMargins = filterMargins(
                margins,
                merge[0].fromCountryCode,
                merge[merge.length - 1].toCountryCode
              )

              let { price, markup } = calculateFlightPrice(
                filteredMargins,
                ff.totalPrice,
                merge[merge.length - 1].toCountryCode,
                false,
                numPassengers,
                'outbound'
              )
              ff.totalPrice = price
              ff.markupOutgoing = markup

              const ffArray = []
              const byFl = ff.byFlights || {}

              for (let flN in byFl) {
                ffArray.push({ ...byFl[flN] })
              }

              ff.ff = ffArray
            }

            dispatch({
              type: CONSTANTS.SET_MULTISTOP_FAMILY_PRICING,
              payload: {
                stopId: stopId,
                fares: multiFetch,
              },
            })
          }
        )

        await Promise.allSettled(farePromises)

        dispatch(isFareFetching(false))
        return
      }

      // Outgoing
      const { trip } = outgoing.original
      const returningOriginal = returning
        ? returning.original.returnTrip
        : returning?.original

      const tripMapped = trip.map((t) => ({ ...t })) //copy array
      const returnTripMapped = !returningOriginal?.length
        ? returningOriginal
        : returningOriginal.map((t) => ({ ...t })) //copy array
      const isMTK = returning?.original?.isMultiTicket || false
      let merge =
        returning && !isMTK ? [...tripMapped, ...returnTripMapped] : tripMapped

      const outgoingData = cleanFamiliesPricing({
        flights: merge,
        passengers,
      })

      if (!!tmpOCabin.length) {
        outgoingData['cabin_class'] = tmpOCabin
      }
      if (!!cabinPolicyGrade) outgoingData['strictCabin'] = true

      dispatch(isFareFetching(true))
      const outgoingFetch = await fetchPostFamiliesPricing(
        outgoingData,
        `fetchOutboundFF${rqCTKType}`
      )
      for (let ff of outgoingFetch) {
        //     console.log(ff.price)
        const filteredMargins = filterMargins(
          margins,
          outgoing.from.countryCode,
          outgoing.to.countryCode
        )
        let { price, markup } = calculateFlightPrice(
          filteredMargins,
          ff.totalPrice,
          outgoing.to.countryCode,
          false,
          numPassengers,
          !isMTK && isTwoWay ? 'both' : 'outbound'
        )
        ff.totalPrice = price
        ff.markupOutgoing = markup
        if (!isMTK && isTwoWay) {
          ff.markupOutgoing = Math.round(markup / 2)
          ff.markupReturn = Math.round(markup / 2)
        }

        // console.log(
        //   `price ${price} markupOutgoing ${ff.markupOutgoing} markupReturn ${ff.markupReturn}`
        // )

        const ffArray = []
        const byFl = ff.byFlights || {}

        for (let flN in byFl) {
          ffArray.push({ ...byFl[flN] })
        }

        ff.ff = ffArray
      }
      dispatch({
        type: CONSTANTS.SET_OUTGOING_FAMILY_PRICING,
        payload: outgoingFetch,
      })

      if (isMTK) {
        const returningdata = cleanFamiliesPricing({
          flights: returnTripMapped,
          passengers,
        })

        if (!!tmpOCabin.length) {
          returningdata['cabin_class'] = tmpOCabin
        }
        if (!!cabinPolicyGrade) outgoingData['strictCabin'] = true

        const returningFetch = await fetchPostFamiliesPricing(
          returningdata,
          `fetchInboundFF${rqCTKType}`
        )
        const filteredMargins = filterMargins(
          margins,
          returning.from.countryCode,
          returning.to.countryCode
        )
        for (let ff of returningFetch) {
          let { price: returnPrice, markup: returnMarkup } =
            calculateFlightPrice(
              filteredMargins,
              ff.totalPrice,
              returning.to.countryCode,
              false,
              numPassengers,
              'inbound'
            )
          ff.totalPrice = returnPrice
          ff.markupReturn = returnMarkup

          // console.log(`returnPrice ${returnPrice} returnMarkup ${returnMarkup}`)

          const ffArray = []
          const byFl = ff.byFlights || {}

          for (let flN in byFl) {
            ffArray.push({ ...byFl[flN] })
          }

          ff.ff = ffArray
        }

        dispatch({
          type: CONSTANTS.SET_RETURNING_FAMILY_PRICING,
          payload: returningFetch,
        })
      }
      dispatch(isFareFetching(false))
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(isFareFetching(false))
    }
  }

export const getFlightMiddleStepSettingsAction = () => async (dispatch) => {
  const fetchMiddleStep = await fetchGetMiddleStepSettings()

  dispatch({
    type: CONSTANTS.SET_FLIGHT_MIDDLESET_SETTINGS,
    payload: fetchMiddleStep,
  })
}

// Loading
const isSeatsFetching = (isFetching) => ({
  type: CONSTANTS.SET_FLIGHT_SEAT_LOADING,
  payload: isFetching,
})

export const clearAllFlightDetailsAction = () => (dispatch) => {
  cancelRyByKeyPart(rqCTKType)
  dispatch({ type: CONSTANTS.FLIGHT_DETAILS_CLEAR_ALL })
}

export const setPassengerSeatings = (passengers) => (dispatch) => {
  dispatch({ type: CONSTANTS.SET_PASSENGER_SEATS, payload: passengers })
}

export const setTotalPrice = (price, fare) => ({
  type: CONSTANTS.SET_TOTAL_PRICE,
  payload: { price, fare },
})

export const setOutboundFare = (fare) => ({
  type: CONSTANTS.SET_OUTBOUND_FARE_FAMILY,
  payload: { fare },
})

export const setReturnFare = (fare) => ({
  type: CONSTANTS.SET_RETURN_FARE_FAMILY,
  payload: { fare },
})

export const setMultistopFare = (stopId, fare) => ({
  type: CONSTANTS.SET_MULTISTOP_FARE_FAMILY,
  payload: { stopId, fare },
})
export const clearCatalogueData = () => ({
  type: CONSTANTS.CLEAR_CATALOGUE_DATA,
})

export const setUserBaggage = (
  userId,
  value,
  type = 'outbound',
  bagType = 'general'
) => ({
  type: CONSTANTS.SET_USER_BAGGAGE,
  payload: { type, value, userId, bagType },
})

export const setMultistopUserBaggage = (
  stopId,
  userId,
  value,
  bagType = 'general'
) => ({
  type: CONSTANTS.SET_MULTISTOP_BAGGAGE,
  payload: { stopId, value, userId, bagType },
})
export const clearSelectedBaggage = () => ({
  type: CONSTANTS.CLEAR_SELECTED_BAGGAGE,
})

const isFareFetching = (isFetching) => ({
  type: CONSTANTS.SET_FARE_LOADING,
  payload: isFetching,
})

const isCatalogueFetching = (isFetching) => ({
  type: CONSTANTS.SET_CATALOGUE_LOADING,
  payload: isFetching,
})
