import React, { useEffect, Fragment, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import {
  fetchOutgoingFlightAction,
  clearFlightSearch,
  fetchReturningFlightAction,
  fetchMultiFlightAction,
  setFlightsPassengers,
  setMultiFlight,
} from '../../store/flights/searchAction'
import MinimizedSearch from './../../containers/search/mimizedSearch'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'
import MobileHeader from '../../containers/header/mobileHeader/mobileHeader'
import useOneWay from '../../hooks/useOneWayHook'
import useDirections from '../../hooks/useDirectionsHook'
import useDestinations from '../../hooks/useSwitchDestinationsHook'
import { optionLabel } from '../../utils/flights/optionLabel'
import useFlightsHook from '../../hooks/useFlightsHook'
import useTravellers from '../../hooks/useTravellersHook'
import usePopover from '../../hooks/usePopoverHook'
import { buildUsersByCount } from '../../utils/general'
import {
  disableExtendCheckout,
  setSearchTravellers,
} from '../../store/search/searchAction'
import checkoutInlineSearch from '../../containers/search/checkoutInlineSearch'

const FlightSearchPageForm = ({
  searchParams,
  searchType = 'outbound',
  beforeSearch = null,
  isExtendingFromCheckout = false,
}) => {
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const {
    arr = null,
    dep = null,
    from = null,
    to = null,
    isOneWay,
    initialTicketClass = 'economy',
    tripNumber = null,
  } = searchParams || {}
  const {
    outgoingSearchToken = null,
    outgoing,
    airports,
  } = useSelector((state) => state.flights)
  const { travellers: passengersRedux, extendCheckout } = useSelector(
    (state) => state.search.flights
  )
  const { items } = useSelector((state) => state.checkout)
  useEffect(() => {
    const flight = items.find((i) => {
      return i.type === 'Flight'
    })

    if (!items.length) {
      dispatch(disableExtendCheckout())
    } else if ((!!flight || !user) && !!extendCheckout) {
      history.push('/checkout')
    }
  }, [items, extendCheckout, dispatch])

  const searchToken = searchType === 'return' ? outgoingSearchToken : null

  const { isAuthenticated, user } = useSelector((state) => state.auth)
  const singlePnrBooking = user?.singlePnrBooking

  const earliestDate = user?.bookingLink?.earliestDate
  const latestDate = user?.bookingLink?.latestDate

  // Support for multistop search
  const currentDep = searchType === 'multi' ? dep?.split('-')[tripNumber] : dep
  const currentFrom =
    searchType === 'multi' ? from?.split('-')[tripNumber] : from
  const currentTo = searchType === 'multi' ? to?.split('-')[tripNumber] : to

  const isToLocked = user?.bookingLink?.allowedFlightDestination?.length > 0
  const { departure, depChange, arrival, arrOnChange, setArrival } =
    useDirections(currentDep, arr, true)
  const { isOneWayDest } = useOneWay(arr, isOneWay)
  const { travellers, setTravellers } = useTravellers(passengersRedux)
  const { switchDestinations, setFrom, setTo, fromDest, toDest } =
    useDestinations(currentFrom, currentTo, i18n, 'flight')
  const {
    isPopoverOpenFrom,
    setIsPopoverOpenFrom,
    isPopoverOpenTo,
    setIsPopoverOpenTo,
    isPopoverOpenTraveller,
    setIsPopoverOpenTraveller,
  } = usePopover()
  const {
    getCities,
    onSubmit,
    counts,
    modifyCounts,
    ticketClass,
    setTicketClass,
    ticketClasses,
  } = useFlightsHook({
    fromDest,
    toDest,
    history,
    currentFrom,
    currentTo,
    i18n,
    arrival,
    departure,
    dispatch,
    isOneWayDest,
    travellers,
    isOutgoing: true,
    setIsPopoverOpenFrom,
    setIsPopoverOpenTo,
    setIsPopoverOpenTraveller,
    initialTicketClass,
    forceNewSearch: false,
    isExtendingFromCheckout,
  })

  useEffect(() => {
    // For direct links to search, passengers are not available in storage
    // and must be intialized from search query.
    if (passengersRedux.length === 0) {
      let travellersFromUrl = buildUsersByCount({
        adult: { count: searchParams.adult },
        child: { count: searchParams.child },
        infant: { count: searchParams.infant },
      })

      dispatch(setSearchTravellers(travellersFromUrl))
    }
  }, [])

  useEffect(() => {
    dispatch(setFlightsPassengers(travellers))
  }, [travellers])

  useEffect(() => {
    if (isOneWayDest) {
      setArrival(null)
    }
  }, [setArrival, isOneWayDest])

  useEffect(() => {
    if (travellers.length === 0) return

    // fetch trip
    const payload = {
      arr,
      dep,
      from,
      to,
      passengers: travellers,
      ticketClass,
      tripNumber,
    }
    // console.log(payload)
    if (searchType === 'outbound') {
      payload.isOneWay = isOneWay
      dispatch(fetchOutgoingFlightAction(payload, i18n.language))
    } else if (searchType === 'return') {
      dispatch(clearFlightSearch())
      payload.outgoingSearchToken = searchToken
      dispatch(fetchReturningFlightAction(payload, outgoing, i18n.language))
    } else if (searchType === 'multi') {
      // Clear previous chosen flight if set
      dispatch(setMultiFlight(null, tripNumber))

      // Search
      dispatch(fetchMultiFlightAction(payload, i18n.language))
    }
    return () => {
      dispatch(clearFlightSearch())
    }
  }, [
    dispatch,
    arr,
    dep,
    from,
    to,
    travellers,
    isOneWay,
    i18n.language,
    ticketClass,
    outgoing,
    searchType,
    tripNumber,
  ])

  const renderOption = (option) => {
    return (
      <Fragment>
        <Box mr={1}>
          <LocationOnIcon color="disabled" fontSize="large" />
        </Box>
        <Typography>
          {option['City']}, {option['Country']} ({option.IATA})
        </Typography>
      </Fragment>
    )
  }

  const disableUsersSelect =
    !!extendCheckout && !singlePnrBooking && !isExtendingFromCheckout

  const SearchComponent = isExtendingFromCheckout
    ? checkoutInlineSearch
    : MinimizedSearch

  const submitSearch = useCallback(() => {
    if (beforeSearch && typeof beforeSearch === 'function') {
      beforeSearch()
    }
    onSubmit()
  }, [beforeSearch, onSubmit])

  return (
    <>
      {isMobile && !isExtendingFromCheckout ? <MobileHeader /> : ''}
      <SearchComponent
        from={fromDest}
        setFrom={setFrom}
        to={toDest}
        setTo={setTo}
        /* */
        departure={departure}
        setDeparture={depChange}
        arrival={arrival}
        setArrival={arrOnChange}
        hideArrival={searchType === 'multi'}
        /* */
        disableUsersSelect={disableUsersSelect}
        travellers={travellers}
        setTravellers={setTravellers}
        switchDestinations={switchDestinations}
        getCities={getCities}
        isOneWay={isOneWayDest}
        onSubmit={submitSearch}
        array={airports}
        optionLabel={optionLabel}
        renderOption={renderOption}
        setIsPopoverOpenFrom={setIsPopoverOpenFrom}
        isPopoverOpenFrom={isPopoverOpenFrom}
        setIsPopoverOpenTo={setIsPopoverOpenTo}
        isPopoverOpenTo={isPopoverOpenTo}
        setIsPopoverOpenTraveller={setIsPopoverOpenTraveller}
        isPopoverOpenTraveller={isPopoverOpenTraveller}
        counts={isAuthenticated ? null : counts}
        modifyCounts={modifyCounts}
        setTicketClass={setTicketClass}
        ticketClass={ticketClass}
        ticketClasses={ticketClasses}
        earliestDate={earliestDate}
        latestDate={latestDate}
        isToLocked={isToLocked}
        extendCheckout={extendCheckout}
      />
    </>
  )
}

export default FlightSearchPageForm
