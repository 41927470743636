import React, { memo, useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Moment from 'react-moment'
import Typography from '@material-ui/core/Typography'
import { useParams } from 'react-router-dom'
import { fetchCityByIata } from '../../../../../repositories/flights'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  cityText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  dateText: {
    color: theme.palette.dimGray,
  },
}))

const FlightType = ({ searchType }) => {
  const classes = useStyles()
  const params = useParams()

  const [start, setStart] = useState('')
  const [destination, setDestination] = useState('')

  const fetchAwaits = async (param, set) => {
    const result = await fetchCityByIata(param)
    set(result)
  }

  const isMultistop = params.tripNumber !== undefined

  const dep = isMultistop ? params.dep?.split('-')[params.tripNumber] : params.dep
  const arr = isMultistop ? params.arr?.split('-')[params.tripNumber] : params.arr

  useEffect(() => {
    // Support for multistop search
    if (params.tripNumber !== undefined) {
      const from = params.from?.split('-')[params.tripNumber]
      const to = params.to?.split('-')[params.tripNumber]
      fetchAwaits(from, setStart)
      fetchAwaits(to, setDestination)
    } else {
      fetchAwaits(params.from, setStart)
      fetchAwaits(params.to, setDestination)
    }
    return () => {
      setStart('')
      setDestination('')
    }
  }, [params.from, params.to, params.tripNumber])

  return (
    <Box className={classes.root}>
      <Typography className={classes.cityText} align="center">
        <>
          {start ? start['City SWE'] : ''} -{' '}
          {destination ? destination['City SWE'] : ''}
        </>
      </Typography>
      <Typography variant="caption" align="center" className={classes.dateText}>
        <Moment format="ddd D MMM">{dep}</Moment>
        {arr !== '0' ? (
          <>
            {` - `}
            <Moment format="ddd D MMM ">{arr}</Moment>
          </>
        ) : null}
      </Typography>
    </Box>
  )
}

export default memo(FlightType)
